import React from "react";
import Footer from "src/components/Footer";

class Page extends React.Component {
  render() {
    return (
      <div>
        
        <div className="addbg2">
          <div className="wrapper">
            <header>
              <h1 className="logo">
                <a href="/">Neutrogena</a>
              </h1>
              <nav>
                <a href="/produkty/" className="link">
                  Produkty
                </a>
                <span>| </span>
                <a href="/neutrogena/o-nas/" className="link">
                  NEUTROGENA<sup>®</sup>
                </a>
                <span>| </span>
                <a href="/budte-krasna/" className="link">
                  Buďte krásná
                </a>
                  
                  
              </nav>
            </header>
            <div className="content">
              <div className="breadcrumb">
                <a href="/">
                  NEUTROGENA<sup>®</sup>
                </a>
                <span>&gt;</span>
                <a href="/produkty/">Popis</a>
                <span>&gt;</span>
                <a href="/product/125/#" className="blue">
                  {" "}
                  <p>
                    NEUTROGENA<sup>®</sup>&nbsp;Cellular Boost Hydrogelová maska pro vyhlazení vrásek
                  </p>{" "}
                </a>
              </div>
              <div className="singleproduct">
                <div className="prodimgcont">
                  <div className="bot">
                    <img
                      src="/assets/Uploads/NTG_3574661393735_Cellular_Boost Maska.jpg"
                      alt="NEUTRIGENA Cellular Boost Hydrogelová maska pro vyhlazení vrásek"
                    />
                  </div>
                </div>
                <div className="info">
                  <h2>
                    {" "}
                    <p>
                      NEUTROGENA<sup>®</sup>&nbsp;Cellular Boost Hydrogelová maska pro vyhlazení vrásek
                    </p>
                  </h2>
                  <dl>
                    <dt>Popis:</dt>
                    <dd>
                      <p>„Buněčné vyhoření“ je jednou z hlavních příčin stárnutí pokožky. Je způsobeno UV zářením a znečištěním ovzduší, navíc je urychluje stres a únava. Zpomaluje procesy v buňkách pokožky, což vede k viditelným známkám stárnutí. Objevte hydrogelovou masku NEUTROGENA® Cellular Boost pro vyhlazení vrásek z nové generace pleťových masek. </p>
                      <br/>
                      <p>
                        Maska s inovativní texturou je vyrobena ze 100% hydrogelu se složením pro vyhlazení jemných vrásek, obohaceným o Adenosin. Dokonale přilne k vašemu obličeji jako druhá kůže a maximalizuje tak vstřebávání aktivních látek.
                      </p>
                      <p>
                        <br />
                        Pomáhá zmírňovat viditelné známky stárnutí způsobené „buněčným vyhořením“
                      </p>
                      <p>
                        <br />
                        Jedna maska = lahvička séra v 15 minutách
                      </p>
                      <p>
                        <br />
                        Výsledek: Jemné vrásky jsou redukovány, pleť je na dotek hladší a vypadá mladistvěji.
                      </p> 
                        <br />
                      <p>Neucpává póry. </p>  
                        <br />
                       <p>Vhodné pro citlivou pokožku.</p> 
                                          
                    </dd>
                  </dl>
                  <dl>
                    <dt>Použití:</dt>
                    <dd>
                      <p><b>Krok 1:</b> Vyčistěte si pleť.</p>
                      <br />
                      <p><b>Krok 2:</b> Odstraňte bílou fólii ze zadní strany masky.</p>
                      <br />
                      <p><b>Krok 3:</b> Přiložte hydrogelovou masku na obličej hladkou stranou dolů a nechte působit po dobu 15 až 30 minut.</p>
                      <br />
                      <p><b>Krok 4:</b> Sejměte masku a vmasírujte zbytky přípravku do pokožky obličeje a krku.</p>
                      <br />
                      <p>
                      Pro dosažení nejlepších výsledků používejte 3x týdně.
                      </p>
                      <br />
                      <p>
                      Neoplachujte.
                      </p>
                    </dd>
                  </dl>
                  <dl className="last">
                    <dt>Balení:</dt>
                    <dd>1 ks</dd>
                  </dl>
                </div>
                <div id="fb_35"></div>
              </div>
            </div>
          </div>
        </div>
        <div className="bluebar">
          <div className="bar">
            <div className="wrapper">
              <a href="/neutrogena/o-nas/">
                O ZNAČCE NEUTROGENA<sup>®</sup>
              </a>
              <span>|</span>
              <a href="/neutrogena/norska-receptura/">Norská receptura</a>
              <span>|</span>
              <a href="/neutrogena/historie/">Seznamte se s naší historií</a>
              <span>|</span>
              <a href="/produkty/">
                Zobrazit produkty NEUTROGENA<sup>®</sup>
              </a>
            </div>
          </div>
        </div>
        <Footer></Footer>
        <script
          dangerouslySetInnerHTML={{
            __html:
              "\n$(document).ready(function () {\n$('a').each(function () {\nvar linkhtml = $(this).html();\nif (linkhtml.indexOf('®') > -1 && linkhtml.indexOf('<sup>®</sup>') == -1) {\n$(this).html(linkhtml.replace(\"®\", \"<sup>®</sup>\"));\n}\n});\n});\n"
          }}
        />
        <script
          dangerouslySetInnerHTML={{
            __html:
              "\n$(document).ready(function () {\n$('a').each(function () {\nvar linkhtml = $(this).html();\nif (linkhtml.indexOf('®') > -1 && linkhtml.indexOf('<sup>®</sup>') == -1) {\n$(this).html(linkhtml.replace(\"®\", \"<sup>®</sup>\"));\n}\n});\n});\n"
          }}
        />
        
      </div>
    );
  }
}

export default Page;
